import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'hu',
    resources: {
      hu: {
        translation: {
          common: {
            brand: 'WP Market',
            save: 'Mentés',
            saving: 'Mentés...',
            cancel: 'Mégse',
            yes: 'Igen',
            no: 'Nem',
            copy: 'Másolás',
            copied: 'Másolva!',
            edit: 'Szerkesztés',
            delete: 'Törlés'
          },
          nav: {
            store: 'Áruház',
            licenses: 'Licenszek',
            support: 'Támogatás',
            login: 'Bejelentkezés',
            register: 'Regisztráció',
            logout: 'Kijelentkezés',
            purchases: 'Vásárlásaim',
            myLicenses: 'Licenszeim',
            plugins: 'Plugin-ok',
            users: 'Felhasználók',
            contact: 'Kapcsolat',
            faq: 'GYIK',
            documentation: 'Dokumentáció',
            profile: 'Profil'
          },
          home: {
            title: 'Prémium WordPress Plugin Áruház',
            subtitle: 'Bővítsd weboldaladat prémium minőségű pluginekkel',
            cta: {
              store: 'Plugin Áruház',
              contact: 'Kapcsolat'
            }
          },
          features: {
            title: 'Előnyök',
            subtitle: 'Miért válassz minket?',
            feature1: {
              title: 'Magyar fejlesztés',
              description: 'Minden pluginünket magyar fejlesztők készítik, így garantált a minőség és a megbízhatóság.'
            },
            feature2: {
              title: 'Magyar támogatás',
              description: 'Teljes körű magyar nyelvű támogatást nyújtunk minden termékünkhöz.'
            },
            feature3: {
              title: 'Kedvező árak',
              description: 'Versenyképes árak és rendszeres akciók várják vásárlóinkat.'
            }
          },
          testimonials: {
            title: 'Vélemények',
            subtitle: 'Mit mondanak ügyfeleink?',
          },
          support: {
            title: 'Támogatás',
            subtitle: 'Segítségre van szükséged? Mi itt vagyunk, hogy segítsünk.'
          },
          stats: {
            title: 'Statisztikák',
            subtitle: 'Néhány számunk a mirol',
            plugins: 'Plugin-ek',
            activeUsers: 'Aktív felhasználók',
            purchases: 'Vásárlások',
            support: 'Támogatás'
          },
          faq: {
            title: 'Gyakori kérdések',
            howToBuy: {
              question: 'Hogyan vásárolhatok plugin-eket?',
              answer: 'Regisztrálj, válaszd ki a kívánt plugin-et, add hozzá a kosárhoz, és kövess az eljárást.'
            }
          },
          footer: {
            navigation: 'Navigáció',
            legal: 'Jogi információk',
            privacy: 'Adatvédelem',
            terms: 'ÁSZF'
          },
          auth: {
            login: 'Bejelentkezés',
            register: 'Regisztráció',
            name: 'Teljes név',
            email: 'Email cím',
            password: 'Jelszó',
            or: 'vagy',
            continueWithGoogle: 'Bejelentkezés Google fiókkal',
            alreadyHaveAccount: 'Már van fiókod? Jelentkezz be!',
            needAccount: 'Még nincs fiókod? Regisztrálj!',
            verificationEmailSent: 'Megerősítő e-mailt küldtünk. Kérjük, ellenőrizze a postaládáját!',
            pleaseVerifyEmail: 'Kérjük, erősítse meg e-mail címét a bejelentkezés előtt!',
            invalidCredentials: 'Hibás email cím vagy jelszó',
            userNotFound: 'Nem található felhasználó ezzel az email címmel',
            wrongPassword: 'Hibás jelszó',
            emailInUse: 'Ez az email cím már használatban van',
            invalidEmail: 'Érvénytelen email cím',
            weakPassword: 'A jelszó túl gyenge. Használjon legalább 6 karaktert',
            tooManyAttempts: 'Túl sok sikertelen próbálkozás. Kérjük, próbálja újra később.',
            generalError: 'Hiba történt. Kérjük, próbálja újra.'
          },
          profile: {
            title: 'Profil',
            name: 'Név',
            edit: 'Szerkesztés',
            updateSuccess: 'Profil sikeresen frissítve',
            updateError: 'Hiba történt a profil frissítése során',
            accountDetails: 'Fiók adatok',
            emailVerified: 'Email megerősítve',
            lastLogin: 'Utolsó bejelentkezés',
            accountCreated: 'Fiók létrehozva'
          },
          licenses: {
            myLicenses: 'Licenszeim',
            noLicenses: 'Még nincsenek licenszeid',
            domain: 'Domain',
            key: 'Licensz kulcs',
            enterDomain: 'Add meg a domaint...',
            notSet: 'Nincs beállítva',
            valid: 'Érvényes',
            expired: 'Lejárt',
            expires: 'Lejárat',
            management: {
              title: 'Licensz kezelés',
              addNew: 'Új Licensz',
              searchPlaceholder: 'Keresés plugin, domain, email vagy licensz kulcs alapján...',
            },
            user: 'Felhasználó',
          },
          plugins: {
            title: 'WordPress Plugin-ok kezelése',
            addNew: 'Új Plugin',
            version: 'Verzió',
            price: 'Ár',
            lastUpdated: 'Utolsó frissítés',
            active: 'Aktív',
            inactive: 'Inaktív',
            download: 'Letöltés'
          },
          users: {
            title: 'Felhasználók kezelése',
            searchPlaceholder: 'Keresés név vagy email alapján...',
            admin: 'Admin',
            user: 'Felhasználó',
          }
        }
      },
      en: {
        translation: {
          common: {
            brand: 'WP Market',
            save: 'Mentés',
            saving: 'Mentés...',
            cancel: 'Mégse',
            yes: 'Igen',
            no: 'Nem',
            copy: 'Másolás',
            copied: 'Másolva!',
            edit: 'Edit',
            delete: 'Delete'
          },
          nav: {
            store: 'Store',
            licenses: 'Licenses',
            support: 'Support',
            login: 'Login',
            register: 'Register',
            logout: 'Logout',
            purchases: 'My Purchases',
            myLicenses: 'My Licenses',
            plugins: 'Plugins',
            users: 'Users',
            contact: 'Contact',
            faq: 'FAQ',
            documentation: 'Documentation',
            profile: 'Profile'
          },
          home: {
            title: 'Premium WordPress Plugin Store',
            subtitle: 'Enhance your website with premium quality plugins',
            cta: {
              store: 'Plugin Store',
              contact: 'Contact'
            }
          },
          features: {
            title: 'Features',
            subtitle: 'Why choose us?',
            feature1: {
              title: 'Hungarian Development',
              description: 'All our plugins are made by Hungarian developers, guaranteeing quality and reliability.'
            },
            feature2: {
              title: 'Hungarian Support', 
              description: 'We provide full Hungarian language support for all our products.'
            },
            feature3: {
              title: 'Competitive Prices',
              description: 'Competitive prices and regular promotions await our customers.'
            }   
          },
          testimonials: {
            title: 'Testimonials',
            subtitle: 'What our customers say',
          },
          support: {
            title: 'Support',
            subtitle: 'Need help? We\'re here to assist you.'
          },
          stats: {
            title: 'Stats',
            subtitle: 'Some numbers about us',
            plugins: 'Plugins',
            activeUsers: 'Active Users',
            purchases: 'Purchases',
            support: 'Support'
          },
          faq: {
            title: 'FAQ',
            howToBuy: {
              question: 'How to buy plugins?',
              answer: 'Register, select the desired plugin, add it to the cart, and follow the payment process.'
            },
            paymentMethods: {
              question: 'What payment methods do you accept?',
              answer: 'We accept both credit card and bank transfer payments.'
            }
          },
          auth: {
            verificationEmailSent: 'Verification email sent. Please check your inbox!',
            pleaseVerifyEmail: 'Please verify your email before logging in!',
            invalidCredentials: 'Invalid email or password',
            userNotFound: 'No user found with this email',
            wrongPassword: 'Wrong password',
            emailInUse: 'This email is already in use',
            invalidEmail: 'Invalid email address',
            weakPassword: 'Password is too weak. Use at least 6 characters',
            tooManyAttempts: 'Too many failed attempts. Please try again later.',
            generalError: 'An error occurred. Please try again.'
          },
          profile: {
            title: 'Profile',
            name: 'Name',
            edit: 'Edit',
            updateSuccess: 'Profile updated successfully',
            updateError: 'Error updating profile',
            accountDetails: 'Account Details',
            emailVerified: 'Email verified',
            lastLogin: 'Last login',
            accountCreated: 'Account created'
          },
          licenses: {
            myLicenses: 'My Licenses',
            noLicenses: 'You don\'t have any licenses yet',
            domain: 'Domain',
            key: 'License key',
            enterDomain: 'Enter domain...',
            notSet: 'Not set',
            valid: 'Valid',
            expired: 'Expired',
            expires: 'Expires',
            management: {
              title: 'License Management',
              addNew: 'New License',
              searchPlaceholder: 'Search by plugin, domain, email or license key...',
            },
            user: 'User',
          },
          plugins: {
            title: 'Manage WordPress Plugins',
            addNew: 'New Plugin',
            version: 'Version',
            price: 'Price',
            lastUpdated: 'Last Updated',
            active: 'Active',
            inactive: 'Inactive',
            download: 'Download'
          },
          users: {
            title: 'Manage Users',
            searchPlaceholder: 'Search by name or email...',
            admin: 'Admin',
            user: 'User',
          }
        }
      }
    }
  });

export default i18n; 