import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyCPwUyNrU8IS5J7-uGhL7fOfSDAB771p74",
    authDomain: "wpmarket-2bed7.firebaseapp.com",
    databaseURL: "https://wpmarket-2bed7-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "wpmarket-2bed7",
    storageBucket: "wpmarket-2bed7.firebasestorage.app",
    messagingSenderId: "765543475131",
    appId: "1:765543475131:web:f51ea123dc9d286993469e",
    measurementId: "G-ZHJK78B4J9"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);


export const storage = getStorage(app); 
export const functions = getFunctions(app);