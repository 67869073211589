import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Navbar from './Navbar';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, orderBy, deleteDoc, doc, addDoc, updateDoc } from 'firebase/firestore';
import { License } from '../types/license';
import AddLicenseDialog from './AddLicenseDialog';
import EditLicenseDialog from './EditLicenseDialog';
import { useTranslation } from 'react-i18next';

const generateLicenseKey = (): string => {
  return crypto.randomUUID();
};

const LicenseManager: React.FC = () => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [copiedKey, setCopiedKey] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const licensesRef = collection(db, 'licenses');
        const q = query(licensesRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        
        const licensesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as License[];
        
        setLicenses(licensesData);
      } catch (error) {
        console.error('Error fetching licenses:', error);
      }
    };

    fetchLicenses();
  }, []);

  const handleDeleteLicense = async (id: string) => {
    if (!window.confirm('Biztosan törölni szeretné ezt a licenszt?')) return;

    try {
      await deleteDoc(doc(db, 'licenses', id));
      setLicenses(licenses.filter(license => license.id !== id));
    } catch (error) {
      console.error('Error deleting license:', error);
    }
  };

  const handleAddLicense = async (licenseData: Omit<License, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, 'licenses'), licenseData);
      setLicenses([...licenses, { id: docRef.id, ...licenseData }]);
      setIsAddDialogOpen(false);
    } catch (error) {
      console.error('Error adding license:', error);
    }
  };

  const handleEditLicense = async (id: string, licenseData: Partial<License>) => {
    try {
      await updateDoc(doc(db, 'licenses', id), {
        ...licenseData,
        updatedAt: new Date().toISOString()
      });
      
      setLicenses(licenses.map(license => 
        license.id === id ? { ...license, ...licenseData } : license
      ));
      setIsEditDialogOpen(false);
      setSelectedLicense(null);
    } catch (error) {
      console.error('Error updating license:', error);
    }
  };

  const handleGenerateNewKey = async (id: string) => {
    try {
      const newKey = generateLicenseKey(); // Implement this helper function
      await updateDoc(doc(db, 'licenses', id), {
        licenseKey: newKey,
        updatedAt: new Date().toISOString()
      });
      
      setLicenses(licenses.map(license => 
        license.id === id ? { ...license, licenseKey: newKey } : license
      ));
      setIsEditDialogOpen(false);
      setSelectedLicense(null);
    } catch (error) {
      console.error('Error generating new license key:', error);
    }
  };

  const handleEdit = (license: License) => {
    setSelectedLicense(license);
    setIsEditDialogOpen(true);
  };

  const copyToClipboard = (text: string, licenseId: string) => {
    navigator.clipboard.writeText(text);
    setCopiedKey(licenseId);
    setTimeout(() => setCopiedKey(null), 2000);
  };

  const filteredLicenses = licenses.filter(license => 
    license?.pluginName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license?.domain?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license?.userEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license?.licenseKey?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200 bg-gradient-to-r from-indigo-600 to-blue-500 flex justify-between items-center">
            <h1 className="text-2xl font-bold text-white">{t('licenses.management.title')}</h1>
            <button
              onClick={() => setIsAddDialogOpen(true)}
              className="bg-white text-indigo-600 px-4 py-2 rounded-md flex items-center gap-2 hover:bg-indigo-50 transition-colors duration-150"
            >
              <AddIcon className="w-5 h-5" />
              {t('licenses.management.addNew')}
            </button>
          </div>

          <div className="p-6">
            <div className="relative mb-6">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white 
                         placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 
                         focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder={t('licenses.management.searchPlaceholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="divide-y divide-gray-200">
              {filteredLicenses.map((license) => (
                <div key={license.id} className="py-6 hover:bg-gray-50 transition-colors duration-150">
                  <div className="flex items-start justify-between">
                    <div className="flex-1">
                      <div className="flex items-center gap-4">
                        <h3 className="text-lg font-medium text-gray-900">{license.pluginName}</h3>
                        <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                          new Date(license.expiresAt) >= new Date() 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {new Date(license.expiresAt) >= new Date() 
                            ? t('licenses.valid') 
                            : t('licenses.expired')}
                        </span>
                      </div>

                      <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div>
                          <span className="text-sm font-medium text-gray-500">{t('licenses.domain')}</span>
                          <p className="mt-1 text-sm text-gray-900">{license.domain || t('licenses.notSet')}</p>
                        </div>

                        <div>
                          <span className="text-sm font-medium text-gray-500">{t('licenses.key')}</span>
                          <div className="mt-1 flex items-center gap-2">
                            <code className="text-sm bg-gray-100 px-2 py-1 rounded">{license.licenseKey}</code>
                            <button
                              onClick={() => copyToClipboard(license.licenseKey, license.id)}
                              className="p-1 text-gray-400 hover:text-gray-600"
                              title={t('common.copy')}
                            >
                              <ContentCopyIcon className="w-4 h-4" />
                            </button>
                            {copiedKey === license.id && (
                              <span className="text-green-500 text-xs">{t('common.copied')}</span>
                            )}
                          </div>
                        </div>

                        <div>
                          <span className="text-sm font-medium text-gray-500">{t('licenses.user')}</span>
                          <p className="mt-1 text-sm text-gray-900">{license.userEmail}</p>
                        </div>
                      </div>

                      <div className="mt-2">
                        <span className="text-sm font-medium text-gray-500">{t('licenses.expires')}</span>
                        <p className="mt-1 text-sm text-gray-900">
                          {new Date(license.expiresAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 ml-4">
                      <button
                        onClick={() => handleEdit(license)}
                        className="p-2 text-gray-400 hover:text-indigo-600 rounded-full hover:bg-gray-100"
                        title={t('common.edit')}
                      >
                        <EditIcon className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDeleteLicense(license.id)}
                        className="p-2 text-gray-400 hover:text-red-600 rounded-full hover:bg-gray-100"
                        title={t('common.delete')}
                      >
                        <DeleteIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <AddLicenseDialog
          open={isAddDialogOpen}
          onClose={() => setIsAddDialogOpen(false)}
          onSuccess={handleAddLicense}
        />
        
        {isEditDialogOpen && selectedLicense && (
          <EditLicenseDialog
            open={isEditDialogOpen}
            onClose={() => {
              setIsEditDialogOpen(false);
              setSelectedLicense(null);
            }}
            onSave={handleEditLicense}
            onGenerateNewKey={handleGenerateNewKey}
            license={selectedLicense}
          />
        )}
      </div>
    </>
  );
};

export default LicenseManager; 