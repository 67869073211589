import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Plugin } from '../types/plugin';
import Navbar from './Navbar';
import BuyLicenseDialog from './BuyLicenseDialog';
import { useAuth } from '../contexts/AuthContext';
import ImageViewer from './ImageViewer';

const formatDate = (date: any) => {
  if (!date) return 'N/A';
  return new Date(date).toLocaleDateString('hu-HU');
};

const PluginDetails: React.FC = () => {
  const { pluginId } = useParams<{ pluginId: string }>();
  const [plugin, setPlugin] = useState<Plugin | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    const fetchPlugin = async () => {
      try {
        if (!pluginId) return;
        
        const docRef = doc(db, 'plugins', pluginId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setPlugin({ id: docSnap.id, ...docSnap.data() } as Plugin);
        } else {
          setError('Plugin nem található');
        }
      } catch (err) {
        setError('Hiba történt a plugin betöltése közben');
      } finally {
        setLoading(false);
      }
    };

    fetchPlugin();
  }, [pluginId]);

  const handleBuyClick = () => {
    if (!currentUser) {
      navigate('/login', { state: { from: `/plugin/${pluginId}`, plugin: pluginId } });
      return;
    }
    setIsBuyDialogOpen(true);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <div className="container mx-auto px-4 py-8">
          <div className="text-center">
            <p className="text-gray-500">Betöltés...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error || !plugin) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <div className="container mx-auto px-4 py-8">
          <div className="text-center text-red-600">
            <p>{error || 'Plugin nem található'}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          {plugin.screenshots && plugin.screenshots.length > 0 && (
            <div className="w-full relative">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                {plugin.screenshots.map((screenshot, index) => (
                  <div 
                    key={index}
                    className="relative aspect-w-16 aspect-h-9 cursor-pointer group"
                    onClick={() => {
                      setSelectedImageIndex(index);
                      setIsImageViewerOpen(true);
                    }}
                  >
                    <img
                      src={screenshot}
                      alt={`${plugin.name} screenshot ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg hover:opacity-90 transition-opacity"
                    />
                    <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity rounded-lg" />
                  </div>
                ))}
              </div>
            </div>
          )}
          
          <div className="p-8">
            <div className="flex justify-between items-start mb-6">
              <div>
                <h1 className="text-3xl font-bold text-gray-900 mb-2">
                  {plugin.name}
                </h1>
                <p className="text-sm text-gray-500">
                  Utoljára frissítve: {formatDate(plugin.lastUpdated)}
                </p>
              </div>
              <div className="text-right">
                <p className="text-3xl font-bold text-blue-600">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(plugin.price)}
                </p>
                <span className="bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded">
                  v{plugin.currentVersion}
                </span>
              </div>
            </div>

            <div className="prose max-w-none mb-8">
              <h2 className="text-xl font-semibold mb-4">Leírás</h2>
              <div className="text-gray-600 whitespace-pre-wrap">
                {plugin.description || 'Nincs részletes leírás'}
              </div>
            </div>

            <button
              onClick={handleBuyClick}
              className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg
                       hover:bg-blue-700 transition duration-200 ease-in-out
                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                       flex items-center justify-center gap-2"
            >
              {!currentUser ? 'Bejelentkezés a vásárláshoz' : 'Vásárlás'}
            </button>
          </div>
        </div>
      </div>

      <ImageViewer
        images={plugin.screenshots || []}
        isOpen={isImageViewerOpen}
        onClose={() => setIsImageViewerOpen(false)}
        initialIndex={selectedImageIndex}
      />

      {currentUser && (
        <BuyLicenseDialog
          plugin={plugin}
          isOpen={isBuyDialogOpen}
          onClose={() => setIsBuyDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default PluginDetails;