import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { License } from '../types/license';
import { useAuth } from '../contexts/AuthContext';
import Navbar from './Navbar';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';

const MyLicenses: React.FC = () => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const { currentUser } = useAuth();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editDomain, setEditDomain] = useState('');
  const [copiedKey, setCopiedKey] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLicenses = async () => {
      if (!currentUser?.uid) return;

      try {
        const licensesRef = collection(db, 'licenses');
        const q = query(licensesRef, where('userId', '==', currentUser.uid));
        const snapshot = await getDocs(q);
        
        const licensesData = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          const licenseData = docSnapshot.data();
          const pluginRef = doc(db, 'plugins', licenseData.pluginId);
          const pluginDoc = await getDoc(pluginRef);
          const pluginData = pluginDoc.data();
          
          return {
            id: docSnapshot.id,
            ...licenseData,
            pluginName: (pluginData as { name?: string })?.name || 'Unknown Plugin'
          };
        })) as License[];
        
        setLicenses(licensesData);
      } catch (error) {
        console.error('Error fetching licenses:', error);
      }
    };

    fetchLicenses();
  }, [currentUser]);

  const handleSaveDomain = async (licenseId: string) => {
    try {
      const licenseRef = doc(db, 'licenses', licenseId);
      await updateDoc(licenseRef, { domain: editDomain });
      setLicenses(licenses.map(license => 
        license.id === licenseId ? { ...license, domain: editDomain } : license
      ));
      setEditingId(null);
    } catch (error) {
      console.error('Error updating domain:', error);
    }
  };

  const copyToClipboard = (text: string, licenseId: string) => {
    navigator.clipboard.writeText(text);
    setCopiedKey(licenseId);
    setTimeout(() => setCopiedKey(null), 2000);
  };

  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200 bg-gradient-to-r from-indigo-600 to-blue-500">
            <h1 className="text-2xl font-bold text-white">{t('licenses.myLicenses')}</h1>
          </div>

          {licenses.length === 0 ? (
            <div className="p-8 text-center">
              <p className="text-gray-500">{t('licenses.noLicenses')}</p>
            </div>
          ) : (
            <div className="divide-y divide-gray-200">
              {licenses.map((license) => (
                <div key={license.id} className="p-6 hover:bg-gray-50 transition-colors duration-150">
                  <div className="flex items-center justify-between flex-wrap gap-4">
                    <div className="flex-1">
                      <h3 className="text-lg font-medium text-gray-900">
                        {license.pluginName}
                      </h3>
                      <div className="mt-2 flex flex-col sm:flex-row sm:gap-8">
                        <div className="flex items-center text-sm text-gray-500">
                          <span className="font-medium mr-2">{t('licenses.domain')}:</span>
                          {editingId === license.id ? (
                            <div className="flex items-center gap-2">
                              <input
                                type="text"
                                className="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                value={editDomain}
                                onChange={(e) => setEditDomain(e.target.value)}
                                placeholder={t('licenses.enterDomain')}
                              />
                              <button 
                                onClick={() => handleSaveDomain(license.id)}
                                className="p-1 text-indigo-600 hover:text-indigo-800"
                              >
                                <SaveIcon className="w-5 h-5" />
                              </button>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2">
                              <span>{license.domain || t('licenses.notSet')}</span>
                              <button 
                                onClick={() => {
                                  setEditingId(license.id);
                                  setEditDomain(license.domain || '');
                                }}
                                className="p-1 text-gray-400 hover:text-gray-600"
                              >
                                <EditIcon className="w-4 h-4" />
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="flex items-center text-sm text-gray-500 mt-2 sm:mt-0">
                          <span className="font-medium mr-2">{t('licenses.key')}:</span>
                          <div className="flex items-center gap-2">
                            <code className="bg-gray-100 px-2 py-1 rounded">
                              {license.licenseKey}
                            </code>
                            <button
                              onClick={() => copyToClipboard(license.licenseKey, license.id)}
                              className="p-1 text-gray-400 hover:text-gray-600"
                              title={t('common.copy')}
                            >
                              <ContentCopyIcon className="w-4 h-4" />
                            </button>
                            {copiedKey === license.id && (
                              <span className="text-green-500 text-xs">
                                {t('common.copied')}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="text-sm">
                        <span className={`px-3 py-1 rounded-full ${
                          new Date(license.expiresAt) >= new Date() 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {new Date(license.expiresAt) >= new Date() 
                            ? t('licenses.valid') 
                            : t('licenses.expired')}
                        </span>
                      </div>
                      <div className="text-sm text-gray-500">
                        {t('licenses.expires')}: {new Date(license.expiresAt).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyLicenses; 