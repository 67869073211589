import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { db, auth } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';
import Navbar from './Navbar';

const Profile: React.FC = () => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [displayName, setDisplayName] = useState(currentUser?.displayName || '');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  React.useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser?.uid) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };
    fetchUserData();
  }, [currentUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      const firebaseUser = auth.currentUser;
      if (firebaseUser && currentUser) {
        // Update Auth Profile
        await updateProfile(firebaseUser, {
          displayName: displayName
        });

        // Update Firestore
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
          displayName: displayName,
          updatedAt: new Date().toISOString()
        });

        setSuccess(t('profile.updateSuccess'));
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(t('profile.updateError'));
    } finally {
      setLoading(false);
    }
  };

  if (!currentUser || !userData) return null;

  return (
    <>
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h1 className="text-2xl font-bold mb-6">{t('profile.title')}</h1>

          <div className="flex items-center mb-8">
            {currentUser.photoURL ? (
              <img
                src={currentUser.photoURL}
                alt={currentUser.displayName || ''}
                className="w-24 h-24 rounded-full"
                referrerPolicy="no-referrer"
              />
            ) : (
              <div className="w-24 h-24 rounded-full bg-indigo-100 flex items-center justify-center text-2xl text-indigo-600">
                {currentUser.displayName?.[0]?.toUpperCase() || currentUser.email?.[0]?.toUpperCase()}
              </div>
            )}
            
            <div className="ml-6">
              {isEditing ? (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="displayName" className="block text-sm font-medium text-gray-700">
                      {t('profile.name')}
                    </label>
                    <input
                      type="text"
                      id="displayName"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                    />
                  </div>
                  <div className="flex gap-2">
                    <button
                      type="submit"
                      disabled={loading}
                      className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
                    >
                      {loading ? t('common.saving') : t('common.save')}
                    </button>
                    <button
                      type="button"
                      onClick={() => setIsEditing(false)}
                      className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                      {t('common.cancel')}
                    </button>
                  </div>
                </form>
              ) : (
                <>
                  <h2 className="text-xl font-semibold">{currentUser.displayName}</h2>
                  <p className="text-gray-600">{currentUser.email}</p>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="mt-2 text-indigo-600 hover:text-indigo-700"
                  >
                    {t('profile.edit')}
                  </button>
                </>
              )}
            </div>
          </div>

          {error && (
            <div className="bg-red-50 text-red-600 p-3 rounded-md mb-4">
              {error}
            </div>
          )}

          {success && (
            <div className="bg-green-50 text-green-600 p-3 rounded-md mb-4">
              {success}
            </div>
          )}

          <div className="border-t pt-6">
            <h3 className="text-lg font-semibold mb-4">{t('profile.accountDetails')}</h3>
            <div className="space-y-3">
              <div>
                <span className="text-gray-500">{t('profile.emailVerified')}: </span>
                {userData.emailVerified ? (
                  <span className="text-green-600">{t('common.yes')}</span>
                ) : (
                  <span className="text-red-600">{t('common.no')}</span>
                )}
              </div>
              <div>
                <span className="text-gray-500">{t('profile.lastLogin')}: </span>
                {new Date(userData.lastLogin).toLocaleDateString()}
              </div>
              <div>
                <span className="text-gray-500">{t('profile.accountCreated')}: </span>
                {new Date(userData.createdAt).toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile; 