import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import Navbar from './Navbar';
import AddPluginDialog from './AddPluginDialog';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, query, orderBy, doc, updateDoc, deleteDoc, arrayUnion } from 'firebase/firestore';
import EditPluginDialog from './EditPluginDialog';
import { Plugin, PluginVersion } from '../types/plugin';
import { useTranslation } from 'react-i18next';


const PluginManager: React.FC = () => {
  const [plugins, setPlugins] = useState<Plugin[]>([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedPlugin, setSelectedPlugin] = useState<Plugin | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPlugins = async () => {
      try {
        const pluginsRef = collection(db, 'plugins');
        const q = query(pluginsRef, orderBy('lastUpdated', 'desc'));
        const querySnapshot = await getDocs(q);
        
        const pluginsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Plugin[];
        
        setPlugins(pluginsData);
      } catch (error) {
        console.error('Error fetching plugins:', error);
      }
    };

    fetchPlugins();
  }, []);

  const handleStatusToggle = async (id: string) => {
    try {
      const pluginRef = doc(db, 'plugins', id);
      const plugin = plugins.find(p => p.id === id);
      if (!plugin) return;

      const newStatus = plugin.status === 'active' ? 'inactive' : 'active';
      await updateDoc(pluginRef, {
        status: newStatus
      });

      setPlugins(plugins.map(plugin => 
        plugin.id === id 
          ? { ...plugin, status: newStatus }
          : plugin
      ));
    } catch (error) {
      console.error('Error updating plugin status:', error);
    }
  };

  const handleAddPlugin = async (pluginData: Omit<Plugin, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, 'plugins'), pluginData);
      setPlugins([...plugins, { id: docRef.id, ...pluginData }]);
    } catch (error) {
      console.error('Error adding plugin:', error);
    }
  };

  const handleDeletePlugin = async (id: string) => {
    if (!window.confirm('Biztosan törölni szeretné ezt a plugint?')) return;

    try {
      await deleteDoc(doc(db, 'plugins', id));
      setPlugins(plugins.filter(plugin => plugin.id !== id));
    } catch (error) {
      console.error('Error deleting plugin:', error);
    }
  };

  const handleEditPlugin = async (id: string, pluginData: Partial<Plugin> & { newVersion?: PluginVersion }) => {
    try {
      setIsUploading(true);
      const pluginRef = doc(db, 'plugins', id);

      if (pluginData.newVersion) {
        await updateDoc(pluginRef, {
          ...pluginData,
          versions: arrayUnion(pluginData.newVersion),
          currentVersion: pluginData.newVersion.version,
          lastUpdated: new Date().toISOString()
        });
      } else {
        await updateDoc(pluginRef, {
          ...pluginData,
          lastUpdated: new Date().toISOString()
        });
      }

      setPlugins(plugins.map(plugin => 
        plugin.id === id 
          ? { 
              ...plugin, 
              ...pluginData,
              ...(pluginData.newVersion && {
                versions: [...plugin.versions, pluginData.newVersion],
                currentVersion: pluginData.newVersion.version
              })
            }
          : plugin
      ));

      setIsEditDialogOpen(false);
    } catch (error) {
      console.error('Error updating plugin:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSelectPlugin = (plugin: Plugin) => {
    setSelectedPlugin(plugin);
    setIsEditDialogOpen(true);
  };

  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200 bg-gradient-to-r from-indigo-600 to-blue-500 flex justify-between items-center">
            <h1 className="text-2xl font-bold text-white">{t('plugins.title')}</h1>
            <button
              className="bg-white text-indigo-600 px-4 py-2 rounded-md flex items-center gap-2 hover:bg-indigo-50 transition-colors duration-150"
              onClick={() => setIsAddDialogOpen(true)}
            >
              <AddIcon className="w-5 h-5" />
              {t('plugins.addNew')}
            </button>
          </div>

          <div className="divide-y divide-gray-200">
            {plugins.map((plugin) => (
              <div key={plugin.id} className="p-6 hover:bg-gray-50 transition-colors duration-150">
                <div className="flex items-start justify-between">
                  <div className="flex-1">
                    <div className="flex items-center gap-4">
                      <h3 className="text-lg font-medium text-gray-900">{plugin.name}</h3>
                      <span
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          plugin.status === 'active'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-gray-100 text-gray-800'
                        } cursor-pointer`}
                        onClick={() => handleStatusToggle(plugin.id)}
                      >
                        {plugin.status === 'active' ? t('plugins.active') : t('plugins.inactive')}
                      </span>
                    </div>
                    <p className="mt-1 text-sm text-gray-500">{plugin.description}</p>
                    
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <div>
                        <span className="text-sm font-medium text-gray-500">{t('plugins.version')}</span>
                        <div className="mt-1 flex items-center gap-2">
                          <span className="text-sm text-gray-900">v{plugin.currentVersion}</span>
                          {plugin.versions?.length > 0 && (
                            <a
                              href={plugin.versions[plugin.versions.length - 1].fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-indigo-600 hover:text-indigo-800"
                              title={t('plugins.download')}
                            >
                              <DownloadIcon className="w-4 h-4" />
                            </a>
                          )}
                        </div>
                      </div>
                      
                      <div>
                        <span className="text-sm font-medium text-gray-500">{t('plugins.price')}</span>
                        <p className="mt-1 text-sm text-gray-900">
                          {new Intl.NumberFormat('hu-HU', {
                            style: 'currency',
                            currency: 'HUF'
                          }).format(plugin.price)}
                        </p>
                      </div>
                      
                      <div>
                        <span className="text-sm font-medium text-gray-500">{t('plugins.lastUpdated')}</span>
                        <p className="mt-1 text-sm text-gray-900">
                          {new Date(plugin.lastUpdated).toLocaleDateString('hu-HU')}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="flex gap-2 ml-4">
                    <button
                      onClick={() => handleSelectPlugin(plugin)}
                      className="p-2 text-gray-400 hover:text-indigo-600 rounded-full hover:bg-gray-100"
                      title={t('common.edit')}
                    >
                      <EditIcon className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => handleDeletePlugin(plugin.id)}
                      className="p-2 text-gray-400 hover:text-red-600 rounded-full hover:bg-gray-100"
                      title={t('common.delete')}
                    >
                      <DeleteIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <AddPluginDialog
          open={isAddDialogOpen}
          onClose={() => setIsAddDialogOpen(false)}
          onAdd={handleAddPlugin}
        />
        <EditPluginDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onSave={handleEditPlugin}
          plugin={selectedPlugin}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      </div>
    </>
  );
};

export default PluginManager; 