import React, { useState, useEffect, useRef } from 'react';
import { Plugin, PluginVersion } from '../types/plugin';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig';
import { useAuth } from '../contexts/AuthContext';

interface EditPluginDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (id: string, pluginData: Partial<Plugin> & { newVersion?: PluginVersion }) => Promise<void>;
  plugin: Plugin | null;
  isUploading: boolean;
  setIsUploading: (uploading: boolean) => void;
}

const EditPluginDialog: React.FC<EditPluginDialogProps> = ({ open, onClose, onSave, plugin, isUploading, setIsUploading }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: 0,
    subscriptionLink: '',
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [screenshots, setScreenshots] = useState<File[]>([]);
  const [existingScreenshots, setExistingScreenshots] = useState<string[]>(plugin?.screenshots || []);
  const screenshotInputRef = useRef<HTMLInputElement>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [releaseNotes, setReleaseNotes] = useState('');
  const [newVersion, setNewVersion] = useState('');

  useEffect(() => {
    if (plugin) {
      setFormData({
        name: plugin.name,
        description: plugin.description,
        price: plugin.price,
        subscriptionLink: plugin.subscriptionLink || '',
      });
      
      if (plugin.versions?.length > 0) {
        const latestVersion = plugin.versions[plugin.versions.length - 1];
        const [major, minor, patch] = latestVersion.version.split('.').map(Number);
        setNewVersion(`${major}.${minor}.${patch + 1}`);
      } else {
        setNewVersion('1.0.0');
      }

      setExistingScreenshots(plugin.screenshots || []);
      setScreenshots([]);
      setSelectedFile(null);
      setUploadProgress(0);
      setReleaseNotes('');
    }
  }, [plugin]);

  const handleUpload = async () => {
    if (!selectedFile || !currentUser) return '';
    
    setIsUploading(true);
    setUploadProgress(0);
    
    try {
      const storageRef = ref(storage, `plugins/${crypto.randomUUID()}-${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);
      
      return new Promise<string>((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            setIsUploading(false);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setIsUploading(false);
            resolve(downloadURL);
          }
        );
      });
    } catch (error) {
      setIsUploading(false);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let newVersionData: PluginVersion | undefined;
      
      if (selectedFile) {
        const fileUrl = await handleUpload();
        newVersionData = {
          version: newVersion,
          fileUrl,
          fileName: selectedFile.name,
          releaseNotes,
          releasedAt: new Date().toISOString(),
        };
      }

      const newScreenshotUrls = await uploadScreenshots();
      
      onSave(plugin?.id || '', {
        ...formData,
        screenshots: [...existingScreenshots, ...newScreenshotUrls],
        lastUpdated: new Date().toISOString(),
        ...(newVersionData && {
          currentVersion: newVersion,
          newVersion: newVersionData,
        }),
      });
      onClose();
    } catch (error) {
      console.error('Error saving plugin:', error);
    }
  };

  const handleScreenshotSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files).filter(file => 
        file.type.startsWith('image/')
      );
      setScreenshots(prev => [...prev, ...files]);
    }
  };

  const handleDeleteExistingScreenshot = (urlToDelete: string) => {
    setExistingScreenshots(prev => prev.filter(url => url !== urlToDelete));
  };

  const uploadScreenshots = async () => {
    const urls = await Promise.all(
      screenshots.map(async (file) => {
        const storageRef = ref(storage, `plugin-screenshots/${crypto.randomUUID()}-${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        
        return new Promise<string>((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            (error) => reject(error),
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      })
    );
    return urls;
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type !== 'application/zip' && !file.name.endsWith('.zip')) {
        alert('Please select a ZIP file');
        return;
      }
      setSelectedFile(file);
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
        
        <div className="relative bg-white rounded-lg shadow-xl max-w-4xl w-full">
          <div className="px-6 py-4 border-b">
            <h2 className="text-xl font-semibold text-gray-900">Plugin szerkesztése</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-6">
              {/* Plugin alapadatok */}
              <div className="space-y-6">
                <h3 className="text-lg font-medium text-gray-900 border-b pb-2">
                  Alapadatok
                </h3>
                
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Plugin neve
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      required
                      className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Ár
                    </label>
                    <input
                      type="number"
                      value={formData.price}
                      onChange={(e) => setFormData({ ...formData, price: Number(e.target.value) })}
                      required
                      className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Stripe Subscription Link
                    </label>
                    <input
                      type="text"
                      value={formData.subscriptionLink}
                      onChange={(e) => setFormData({ ...formData, subscriptionLink: e.target.value })}
                      required
                      placeholder="https://buy.stripe.com/..."
                      className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Leírás
                    </label>
                    <textarea
                      value={formData.description}
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      rows={4}
                      className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>

                  {/* Képernyőképek szekció */}
                  <div className="space-y-4">
                    <h4 className="text-sm font-medium text-gray-700">Képernyőképek</h4>
                    <div className="mt-4">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        className="hidden"
                        ref={screenshotInputRef}
                        onChange={handleScreenshotSelect}
                      />
                      <button
                        type="button"
                        onClick={() => screenshotInputRef.current?.click()}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 flex items-center justify-center gap-2"
                      >
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        Képernyőképek feltöltése
                      </button>
                    </div>

                    {/* Existing screenshots */}
                    {existingScreenshots.length > 0 && (
                      <div className="mt-4">
                        <h3 className="text-sm font-medium text-gray-700 mb-2">Meglévő képernyőképek:</h3>
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                          {existingScreenshots.map((url, index) => (
                            <div key={index} className="relative group aspect-w-16 aspect-h-9">
                              <img 
                                src={url} 
                                alt={`Screenshot ${index + 1}`} 
                                className="w-full h-full object-cover rounded-lg"
                              />
                              <button
                                type="button"
                                onClick={() => handleDeleteExistingScreenshot(url)}
                                className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-sm opacity-0 group-hover:opacity-100 transition-opacity"
                              >
                                <svg className="w-4 h-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* New screenshots */}
                    {screenshots.length > 0 && (
                      <div className="mt-4">
                        <h3 className="text-sm font-medium text-gray-700 mb-2">Új képernyőképek:</h3>
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                          {screenshots.map((file, index) => (
                            <div key={index} className="relative group aspect-w-16 aspect-h-9">
                              <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                                className="w-full h-full object-cover rounded-lg"
                                onLoad={() => URL.revokeObjectURL(URL.createObjectURL(file))}
                              />
                              <button
                                type="button"
                                onClick={() => setScreenshots(prev => prev.filter((_, i) => i !== index))}
                                className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-sm opacity-0 group-hover:opacity-100 transition-opacity"
                              >
                                <svg className="w-4 h-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              </button>
                              <div className="absolute bottom-2 left-2 right-2">
                                <p className="text-xs text-white bg-black bg-opacity-50 rounded px-2 py-1 truncate">
                                  {file.name}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Verziókezelés */}
              <div className="space-y-6">
                <h3 className="text-lg font-medium text-gray-900 border-b pb-2">
                  Verziókezelés
                </h3>

                {/* Jelenlegi verzió megjelenítése */}
                {plugin && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Jelenlegi verzió</h4>
                    <div className="text-sm text-gray-600">
                      <p>Verzió: v{plugin.currentVersion}</p>
                      {plugin.versions?.length > 0 && (
                        <p className="mt-1">
                          Utolsó frissítés: {new Date(plugin.versions[plugin.versions.length - 1].releasedAt).toLocaleDateString()}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {/* Új verzió feltöltése */}
                <div className="space-y-4">
                  <h4 className="text-sm font-medium text-gray-700">Új verzió feltöltése</h4>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Plugin File (ZIP)
                    </label>
                    <div className="flex items-center space-x-4">
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".zip"
                        onChange={handleFileSelect}
                        className="hidden"
                      />
                      <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        Choose File
                      </button>
                      {selectedFile && (
                        <span className="text-sm text-gray-600">
                          {selectedFile.name}
                        </span>
                      )}
                    </div>
                    
                    {/* Upload Progress */}
                    {isUploading && (
                      <div className="mt-2">
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                          <div 
                            className="bg-indigo-600 h-2.5 rounded-full transition-all duration-300"
                            style={{ width: `${uploadProgress}%` }}
                          />
                        </div>
                        <span className="text-sm text-gray-600 mt-1">
                          Uploading: {Math.round(uploadProgress)}%
                        </span>
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Új verzió száma
                    </label>
                    <input
                      type="text"
                      value={newVersion}
                      onChange={(e) => setNewVersion(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="pl. 1.0.1"
                      required={!!selectedFile}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Release Notes
                    </label>
                    <textarea
                      value={releaseNotes}
                      onChange={(e) => setReleaseNotes(e.target.value)}
                      rows={4}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Mi változott ebben a verzióban?"
                      required={!!selectedFile}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-6 py-4 border-t bg-gray-50 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                disabled={isUploading}
                className="px-4 py-2 rounded-md text-gray-700 hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isUploading}
                className={`px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 
                  ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isUploading ? 'Uploading...' : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPluginDialog; 