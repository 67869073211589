import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import Navbar from './Navbar';
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { User } from '../types/user';
import EditUserDialog from './EditUserDialog';
import { useTranslation } from 'react-i18next';

const UserManager: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { currentUser } = useAuth();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const snapshot = await getDocs(usersCollection);
        const usersData = snapshot.docs.map(doc => ({
          uid: doc.id,
          ...doc.data()
        })) as User[];
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleAdminToggle = async (uid: string) => {
    try {
      const userToUpdate = users.find(user => user.uid === uid);
      if (!userToUpdate) return;

      const userRef = doc(db, 'users', userToUpdate.uid);
      await updateDoc(userRef, {
        isAdmin: !userToUpdate.isAdmin
      });

      setUsers(users.map(user => 
        user.uid === uid 
          ? { ...user, isAdmin: !user.isAdmin }
          : user
      ));
    } catch (error) {
      console.error('Error toggling admin status:', error);
      alert('Hiba történt a jogosultság módosítása során!');
    }
  };

  const handleEditUser = async (userId: string, userData: Partial<User>) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, userData);
      
      setUsers(users.map(user => 
        user.uid === userId 
          ? { ...user, ...userData }
          : user
      ));
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setIsEditDialogOpen(true);
  };

  const handleDelete = async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      await deleteDoc(userRef);
      setUsers(users.filter(user => user.uid !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Hiba történt a felhasználó törlésekor!');
    }
  };

  const filteredUsers = users.filter(user => 
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200 bg-gradient-to-r from-indigo-600 to-blue-500">
            <h1 className="text-2xl font-bold text-white">{t('users.title')}</h1>
          </div>

          <div className="p-6">
            <div className="relative mb-6">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white 
                         placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 
                         focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder={t('users.searchPlaceholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="divide-y divide-gray-200">
              {filteredUsers.map((user) => (
                <div key={user.uid} className="py-4 flex items-center justify-between">
                  <div className="flex-1">
                    <div className="flex items-center">
                      {user.photoURL ? (
                        <img
                          src={user.photoURL}
                          alt=""
                          className="h-10 w-10 rounded-full"
                          referrerPolicy="no-referrer"
                        />
                      ) : (
                        <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
                          <span className="text-indigo-600 font-medium text-sm">
                            {user.displayName?.[0]?.toUpperCase() || user.email?.[0]?.toUpperCase()}
                          </span>
                        </div>
                      )}
                      <div className="ml-4">
                        <h3 className="text-sm font-medium text-gray-900">{user.displayName}</h3>
                        <p className="text-sm text-gray-500">{user.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-4">
                    <button
                      onClick={() => handleAdminToggle(user.uid)}
                      className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-150
                        ${user.isAdmin 
                          ? 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200' 
                          : 'bg-gray-100 text-gray-800 hover:bg-gray-200'}`}
                    >
                      {user.isAdmin ? t('users.admin') : t('users.user')}
                    </button>

                    <div className="flex gap-2">
                      <button
                        className={`p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed
                          ${user.uid === currentUser?.uid ? 'text-gray-300' : 'text-gray-400 hover:text-indigo-600'}`}
                        disabled={user.uid === currentUser?.uid}
                        onClick={() => handleEdit(user)}
                        title={t('common.edit')}
                      >
                        <EditIcon className="w-5 h-5" />
                      </button>
                      <button
                        className={`p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed
                          ${user.uid === currentUser?.uid ? 'text-gray-300' : 'text-gray-400 hover:text-red-600'}`}
                        disabled={user.uid === currentUser?.uid}
                        onClick={() => handleDelete(user.uid)}
                        title={t('common.delete')}
                      >
                        <DeleteIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <EditUserDialog
          open={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
            setSelectedUser(null);
          }}
          onSave={handleEditUser}
          user={selectedUser}
        />
      </div>
    </>
  );
};

export default UserManager; 