import React, { useState, useRef } from 'react';
import { Plugin } from '../types/plugin';
import { storage } from '../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

interface AddPluginDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (plugin: Omit<Plugin, 'id'>) => void;
}

const AddPluginDialog: React.FC<AddPluginDialogProps> = ({ open, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    name: '',
    version: '',
    description: '',
    status: 'inactive' as 'active' | 'inactive',
    fileUrl: '',
    fileName: '',
    price: 0,
    subscriptionLink: '',
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [screenshots, setScreenshots] = useState<File[]>([]);
  const screenshotInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.name.endsWith('.zip')) {
        setSelectedFile(file);
        setFormData(prev => ({ ...prev, fileName: file.name }));
      } else {
        alert('Csak .zip fájlokat lehet feltölteni!');
      }
    }
  };

  const handleScreenshotSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files).filter(file => 
        file.type.startsWith('image/')
      );
      setScreenshots(prev => [...prev, ...files]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return null;

    const storageRef = ref(storage, `plugins/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    return new Promise<string>((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Upload error:', error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const uploadScreenshots = async () => {
    const urls = await Promise.all(
      screenshots.map(async (file) => {
        const storageRef = ref(storage, `plugin-screenshots/${crypto.randomUUID()}-${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        
        return new Promise<string>((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            (error) => reject(error),
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      })
    );
    return urls;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedFile) {
      alert('Kérjük, válasszon ki egy fájlt!');
      return;
    }

    try {
      const fileUrl = await handleUpload();
      const screenshotUrls = await uploadScreenshots();
      
      if (fileUrl) {
        onAdd({
          name: formData.name,
          description: formData.description,
          status: formData.status,
          price: formData.price,
          subscriptionLink: formData.subscriptionLink,
          screenshots: screenshotUrls,
          lastUpdated: new Date().toISOString(),
          versions: [{
            version: formData.version,
            fileUrl,
            fileName: selectedFile.name,
            releasedAt: new Date().toISOString()
          }],
          currentVersion: formData.version
        });

        setFormData({
          name: '',
          version: '',
          description: '',
          status: 'inactive',
          fileUrl: '',
          fileName: '',
          price: 0,
          subscriptionLink: '',
        });
        setSelectedFile(null);
        setUploadProgress(0);
        setScreenshots([]);
        onClose();
      }
    } catch (error) {
      console.error('Error during upload:', error);
      alert('Hiba történt a feltöltés során!');
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="min-h-screen px-4 text-center">
        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

        <div className="inline-block w-full max-w-xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-2xl font-semibold text-gray-900">
              Új Plugin hozzáadása
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Plugin neve
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 
                           shadow-sm focus:border-blue-500 focus:ring-blue-500 
                           placeholder-gray-400 transition duration-150 ease-in-out"
                required
                placeholder="Add meg a plugin nevét"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Verzió
              </label>
              <input
                type="text"
                value={formData.version}
                onChange={(e) => setFormData({ ...formData, version: e.target.value })}
                className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 
                           shadow-sm focus:border-blue-500 focus:ring-blue-500 
                           placeholder-gray-400 transition duration-150 ease-in-out"
                required
                placeholder="Add meg a plugin verzióját"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Ár (Ft)
              </label>
              <input
                type="number"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: Number(e.target.value) })}
                className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 
                           shadow-sm focus:border-blue-500 focus:ring-blue-500 
                           placeholder-gray-400 transition duration-150 ease-in-out"
                required
                placeholder="0"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Leírás
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                rows={4}
                className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 
                           shadow-sm focus:border-blue-500 focus:ring-blue-500 
                           placeholder-gray-400 transition duration-150 ease-in-out"
                placeholder="Add meg a plugin leírását"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Státusz
              </label>
              <select
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value as 'active' | 'inactive' })}
                className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 
                           shadow-sm focus:border-blue-500 focus:ring-blue-500 
                           transition duration-150 ease-in-out"
              >
                <option value="active">Aktív</option>
                <option value="inactive">Inaktív</option>
              </select>
            </div>

            <div>
              <input
                type="file"
                accept=".zip"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileSelect}
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Plugin fájl kiválasztása (.zip)
              </button>
              {selectedFile && (
                <p className="mt-2 text-sm text-gray-500">
                  Kiválasztott fájl: {selectedFile.name}
                </p>
              )}
            </div>

            <div>
              <input
                type="file"
                accept="image/*"
                multiple
                className="hidden"
                ref={screenshotInputRef}
                onChange={handleScreenshotSelect}
              />
              <button
                type="button"
                onClick={() => screenshotInputRef.current?.click()}
                className="w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Képernyőképek kiválasztása
              </button>
              {screenshots.length > 0 && (
                <p className="mt-2 text-sm text-gray-500">
                  Kiválasztott képek: {screenshots.length} db
                </p>
              )}
            </div>

            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
            )}

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Mégse
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Feltöltés
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPluginDialog; 